export const useFormatCompactNumber = (number: number, type?: string):
string =>  {

  let options = {};

  if (type === 'compact') {
    options = { notation: 'compact', maximumSignificantDigits: 3 };
  }

  const formatter = Intl.NumberFormat('en', options);
  return formatter.format(number);
};
