'use client';

import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from '../../hooks/use-translation';
import AppPageContext from '../../context/app-page-context';
import SpriteIcon from '../shared/sprite-icon';
import { ShareModal } from './share-modal';
import { useFormatCompactNumber } from '../../hooks/use-format-compact-number';
import { handleToolTipOnOver, handleToolTipMouseOut } from '../shared/ow-tool-tip';
import { SGPreviewItem } from './supported-game-preview-item';
import { SupportedGamesModalWrapper } from '../supported-games-modal/supported-games-modal-wrapper';

export const Stats = (): JSX.Element => {
  const { app } = useContext(AppPageContext);
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const downloadsCount = useFormatCompactNumber(app.downloads, 'compact');
  // const exactDownloadsCount = useFormatCompactNumber(app.downloads);

  const isElectron = app?.isElectron === true;

  useEffect(() => {
    const onScroll = (): void => {
      if (!isOpen) {
        return;
      }

      setIsOpen(false);
    };

    document.addEventListener('scroll', onScroll);

    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, [isOpen]);

  const rateStyle = useMemo<React.CSSProperties>( () => {
    if (!app.initialReviews) {
      return;
    }

    // round to nearest .5 score, to support 5 stars visual in mobile.
    return { '--rating': Math.round(app.initialReviews?.averageRate * 2) / 2 } as React.CSSProperties;
  }, [app.initialReviews]);

  const handleToggleOpen = (): void=> {
    setIsOpen(cur => !cur);
  };

  return (
    <section className='app-stats-section'>

      <ul className='app-stats-list'>

        <li className='stats-item'>
          {app.initialReviews && app.initialReviews.averageRate > 0 &&
            <div className='stats-box'>
              <h2 className='stats-title'>{t('reviews')}</h2>
              <a href='#reviews' className='stats-output'>
                <div className='star-rating' style={rateStyle} />
                <SpriteIcon icon='ratingStar' />
                {app.initialReviews.averageRate}
              </a>
            </div>
          }

          { app.downloads > 0 &&
              <div className='stats-box'>
                <h2 className='stats-title'>{t('downloads')}</h2>
                <span 
                  className='stats-output'
                  // data-tooltip={exactDownloadsCount}
                  // onMouseOver={handleToolTipOnOver}
                  // onMouseOut={handleToolTipMouseOut}
                >
                  <SpriteIcon icon='downloadCount' />
                  {downloadsCount}
                </span>
              </div>
          }
        </li>

        {app.richDetails?.supportedGames &&
          app.richDetails?.supportedGames.length > 0 &&
          <li className='stats-item'>
            <div className='stats-box'>
              <h2 className='stats-title'>{t('supportedGames')}</h2>
              <div className='supported-games-wrapper'>
                <ul className='stats-box-list sg-list'>
                  <SGPreviewItem
                    list={app.richDetails.supportedGames}
                  />
                </ul>
                {app.richDetails.supportedGames.length > 10 &&
                  <SupportedGamesModalWrapper />
                }
              </div>
            </div>
          </li>
        }

        <li className='stats-item'>
          <div className='stats-box'>
            <h2 className='stats-title'>{t('appPlatforms')}</h2>
            <ul className='stats-box-list client-list'>
              <li className='stats-box-item'>

                {!isElectron &&
                  <a href='#technical-details'>
                    <img
                      src='/static_next/img/client-icons/platform-icon.svg'
                      alt={t('technicalDetails.client')}
                      data-tooltip={t('technicalDetails.client')}
                      onMouseOver={handleToolTipOnOver}
                      onMouseOut={handleToolTipMouseOut}
                    />
                  </a>
                }

                {isElectron &&
                  <img
                    src='/static_next/img/client-icons/electron-icon.svg'
                    alt={t('technicalDetails.electron')}
                    data-tooltip={t('technicalDetails.electron')}
                    onMouseOver={handleToolTipOnOver}
                    onMouseOut={handleToolTipMouseOut}
                  />
                }

              </li>
            </ul>
          </div>
        </li>

      </ul>

      <ShareModal
        isOpen={isOpen}
        onClickToggleOpen={handleToggleOpen}
      />

    </section>
  );
};